import Cookies from 'js-cookie';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const defaultState = {
  cookiesSet: false,
  theme: 'light',
  themes: [
    { name: 'light', icon: '🌞' },
    { name: 'dark', icon: '🌙' },
  ],
};

export const GlobalContext = createContext(defaultState);

export const GlobalProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const setCookiesSet = useCallback(
    (isSet) =>
      setState((currentState) => ({ ...currentState, cookiesSet: isSet })),
    []
  );

  useEffect(() => {
    const cookiesIsSet = Cookies.get('default');
    if (cookiesIsSet) setCookiesSet(true);
  }, [setCookiesSet]);

  function setTheme(theme) {
    setState({ ...state, theme });
  }

  return (
    <GlobalContext.Provider
      value={{
        state,
        setCookiesSet,
        setTheme,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export function useGlobalState() {
  return useContext(GlobalContext);
}
